@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #95b494;
  --secondary: #729385;
  --type: #404040;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
